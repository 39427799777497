<template>
  <b-overlay
    :show="item === null"
    rounded="sm"
  >
    <div v-if="item">
      <b-card
        no-body
        class="mb-1"
      >
        <b-card-header
          header-tag="header"
          class="p-0 pb-1 pt-1"
          role="tab"
        >
          <b-button
            v-b-toggle="`accordion-${mid}`"
            block
            variant="info"
          >
            {{ item.modTitle }}
          </b-button>
        </b-card-header>
        <b-collapse
          :id="`accordion-${mid}`"
          accordion="my-accordion"
          role="tabpanel"
        >
          <b-card>
            <b-form>
              <b-tabs pills>
                <b-tab :title="$t('administration.products.tabs.data.label')">
                  <b-row>
                    <b-col
                      cols="12"
                      md="4"
                    >
                      <b-form-group :label="$t('form.avatar.label')">
                        <!-- media -->
                        <b-media no-body>
                          <b-media-aside>
                            <b-link>
                              <b-img
                                ref="previewEl"
                                rounded
                                :src="$options.filters.mediaUrl(item, 'avatar', '150x150')"
                                height="80"
                              />
                            </b-link>
                            <!--/ avatar -->

                            <!-- reset -->
                            <b-button
                              v-if="item.avatar"
                              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                              variant="outline-secondary"
                              size="sm"
                              class="mb-75 mr-75"
                              @click="item.avatar = null"
                            >
                              {{ $t('general.reset') }}
                            </b-button>
                            <!--/ reset -->
                          </b-media-aside>

                          <b-media-body class="mt-75 ml-75">
                            <InitFileManager
                              field="avatar"
                              type="product_avatar"
                              :multiple="false"
                              @fm-selected="selectedAvatar"
                            />

                            <b-row
                              v-if="errors && errors.avatar"
                            >
                              <b-col cols="12">
                                <b-form-invalid-feedback :state="false">
                                  {{ errors.avatar[0] }}
                                </b-form-invalid-feedback>
                              </b-col>
                            </b-row>
                            <b-row
                              v-if="errors && errors['avatar.type']"
                            >
                              <b-col cols="12">
                                <b-form-invalid-feedback :state="false">
                                  {{ errors['avatar.type'][0] }}
                                </b-form-invalid-feedback>
                              </b-col>
                            </b-row>

                          </b-media-body>
                        </b-media>
                        <!--/ media -->
                      </b-form-group>
                    </b-col>
                    <b-col
                      cols="12"
                      md="8"
                    >
                      <b-form-group
                        :label="$t('form.slug.label')"
                        label-for="slug"
                      >
                        <b-form-input
                          id="slug"
                          v-model="item.slug"
                          :state="errors && errors.slug ? false : null"
                        />

                        <b-form-invalid-feedback v-if="errors && errors.slug">
                          {{ errors.slug[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </b-col>

                    <b-col
                      cols="12"
                      md="4"
                    >
                      <b-form-group
                        :label="$t('form.sku.label')"
                        label-for="sku"
                      >
                        <b-form-input
                          id="sku"
                          v-model="item.sku"
                          :state="errors && errors.sku ? false : null"
                        />

                        <b-form-invalid-feedback v-if="errors && errors.sku">
                          {{ errors.sku[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </b-col>
                    <b-col
                      cols="12"
                      md="4"
                    >
                      <b-form-group
                        :label="$t('form.ean.label')"
                        label-for="ean"
                      >
                        <b-form-input
                          id="ean"
                          v-model="item.ean"
                          :state="errors && errors.ean ? false : null"
                        />

                        <b-form-invalid-feedback v-if="errors && errors.ean">
                          {{ errors.ean[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </b-col>
                    <b-col
                      cols="12"
                      md="4"
                    >
                      <b-form-group
                        label="1C ID"
                        label-for="1c_id"
                      >
                        <b-form-input
                          id="1c_id"
                          v-model="item['1c_id']"
                          readonly
                        />
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-tabs>
                    <b-tab
                      v-for="language in languages"
                      :key="language.code"
                    >
                      <template #title>
                        <b-img
                          :src="require('@/assets/images/flags/' + language.code + '.svg')"
                          height="16"
                          width="16"
                          class="mr-1"
                        />
                        <span class="d-none d-sm-inline">{{ language.title }}</span>
                      </template>
                      <b-row>
                        <b-col
                          cols="24"
                          md="12"
                        >
                          <b-form-group
                            :label="$t('form.title.label')"
                            :label-for="'title.' + language.code"
                          >
                            <b-form-input
                              :id="'title.' + language.code"
                              v-model="item.title[language.code]"
                              :state="errors && errors['title.' + language.code] ? false : null"
                            />
                            <b-form-invalid-feedback v-if="errors && errors['title.' + language.code]">
                              {{ errors['title.' + language.code][0] }}
                            </b-form-invalid-feedback>
                          </b-form-group>
                        </b-col>
                      </b-row>
                    </b-tab>
                  </b-tabs>
                </b-tab>
                <b-tab :title="$t('administration.products.tabs.stocks.label')">
                  <div class="repeater-form">
                    <b-row
                      v-for="(stock, index) in item.stocks"
                      :id="'stock' + index"
                      :key="index"
                      ref="row"
                    >
                      <b-col
                        cols="12"
                        md="4"
                      >
                        <b-form-group
                          :label="$t('form.stocks.label')"
                          :label-for="'stocks.' + index + '.stock_id'"
                          :state="errors && errors['stocks.' + index + '.stock_id'] ? false : null"
                        >
                          <v-select
                            :id="'stocks.' + index + '.stock_id'"
                            v-model="stock.stock_id"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="stocksOptions"
                            :reduce="val => val.value"
                            :clearable="false"
                            :input-id="'stocks.' + index + '.stock_id'"
                          />
                          <b-form-invalid-feedback v-if="errors && errors['stocks.' + index + '.stock_id']">
                            {{ errors['stocks.' + index + '.stock_id'][0] }}
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </b-col>
                      <b-col
                        cols="12"
                        md="4"
                      >
                        <b-form-group
                          :label="$t('form.quantity.label')"
                          :label-for="'stocks.' + index + '.quantity'"
                        >
                          <b-form-input
                            :id="'stocks.' + index + '.quantity'"
                            v-model="stock.quantity"
                            :state="errors && errors['stocks.' + index + '.quantity'] ? false : null"
                          />

                          <b-form-invalid-feedback v-if="errors && errors['stocks.' + index + '.quantity']">
                            {{ errors['stocks.' + index + '.quantity'][0] }}
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </b-col>
                      <!-- Remove Button -->
                      <b-col
                        cols="12"
                        md="4"
                        class="mb-50"
                      >
                        <b-button
                          v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                          variant="outline-danger"
                          class="mt-0 mt-md-2"
                          block
                          @click="removeStock(index)"
                        >
                          <feather-icon
                            icon="XIcon"
                            class="mr-25"
                          />
                          <span>{{ $t('general.delete') }}</span>
                        </b-button>
                      </b-col>
                      <b-col cols="12">
                        <hr>
                      </b-col>
                    </b-row>
                  </div>

                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    @click="addStock"
                  >
                    <feather-icon
                      icon="PlusIcon"
                      class="mr-25"
                    />
                    <span>{{ $t('general.add') }}</span>
                  </b-button>

                  <b-row
                    v-if="errors && errors.stocks"
                  >
                    <b-col cols="12">
                      <b-form-invalid-feedback :state="false">
                        {{ errors.stocks[0] }}
                      </b-form-invalid-feedback>
                    </b-col>
                  </b-row>

                  <b-row>
                    <b-col cols="12">
                      <hr>
                    </b-col>
                  </b-row>
                </b-tab>
                <b-tab :title="$t('administration.products.tabs.prices.label')">
                  <div class="repeater-form">
                    <b-row
                      v-for="(price, index) in item.prices"
                      :id="'price' + index"
                      :key="index"
                      ref="row"
                    >
                      <b-col
                        cols="12"
                        md="4"
                      >
                        <b-form-group
                          :label="$t('form.role.label')"
                          :label-for="'prices.' + index + '.role_id'"
                          :state="errors && errors['prices.' + index + '.role_id'] ? false : null"
                        >
                          <v-select
                            :id="'prices.' + index + '.role_id'"
                            v-model="price.role_id"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="rolesOptions"
                            :reduce="val => val.value"
                            :clearable="false"
                            :input-id="'prices.' + index + '.role_id'"
                          />
                          <b-form-invalid-feedback v-if="errors && errors['prices.' + index + '.role_id']">
                            {{ errors['prices.' + index + '.role_id'][0] }}
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </b-col>
                      <b-col
                        cols="12"
                        md="4"
                      >
                        <b-form-group
                          :label="$t('form.price.label')"
                          :label-for="'prices.' + index + '.price'"
                        >
                          <b-form-input
                            :id="'prices.' + index + '.price'"
                            v-model="price.price"
                            :state="errors && errors['prices.' + index + '.price'] ? false : null"
                          />

                          <b-form-invalid-feedback v-if="errors && errors['prices.' + index + '.price']">
                            {{ errors['prices.' + index + '.price'][0] }}
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </b-col>
                      <b-col
                        cols="12"
                        md="4"
                        class="mb-50"
                      >
                        <b-button
                          v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                          variant="outline-danger"
                          class="mt-0 mt-md-2"
                          block
                          @click="removePrice(index)"
                        >
                          <feather-icon
                            icon="XIcon"
                            class="mr-25"
                          />
                          <span>{{ $t('general.delete') }}</span>
                        </b-button>
                      </b-col>
                      <b-col cols="12">
                        <hr>
                      </b-col>
                    </b-row>
                  </div>

                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    @click="addPrice"
                  >
                    <feather-icon
                      icon="PlusIcon"
                      class="mr-25"
                    />
                    <span>{{ $t('general.add') }}</span>
                  </b-button>

                  <b-row
                    v-if="errors && errors.prices"
                  >
                    <b-col cols="12">
                      <b-form-invalid-feedback :state="false">
                        {{ errors.prices[0] }}
                      </b-form-invalid-feedback>
                    </b-col>
                  </b-row>

                  <b-row>
                    <b-col cols="12">
                      <hr>
                    </b-col>
                  </b-row>
                </b-tab>
                <b-tab :title="$t('administration.products.tabs.discounts.label')">
                  <div class="repeater-form">
                    <b-row
                      v-for="(discount, index) in item.discounts"
                      :id="'discount' + index"
                      :key="index"
                      ref="row"
                    >
                      <b-col
                        cols="12"
                        md="5"
                      >
                        <b-form-group
                          :label="$t('form.role.label')"
                          :label-for="'discounts.' + index + '.role_id'"
                          :state="errors && errors['discounts.' + index + '.role_id'] ? false : null"
                        >
                          <v-select
                            :id="'discounts.' + index + '.role_id'"
                            v-model="discount.role_id"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="rolesOptions"
                            :reduce="val => val.value"
                            :clearable="false"
                            :input-id="'discounts.' + index + '.role_id'"
                          />
                          <b-form-invalid-feedback v-if="errors && errors['discounts.' + index + '.role_id']">
                            {{ errors['discounts.' + index + '.role_id'][0] }}
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </b-col>
                      <b-col
                        cols="12"
                        md="2"
                      >
                        <b-form-group
                          :label="$t('form.percent.label')"
                          :label-for="'discounts.' + index + '.percent'"
                        >
                          <b-form-input
                            :id="'discounts.' + index + '.percent'"
                            v-model="discount.percent"
                            :type="`number`"
                            :step="`1`"
                            :min="`1`"
                            :max="`99`"
                            @input="setDiscountPercent(index, $event)"
                          />
                        </b-form-group>
                      </b-col>
                      <b-col
                        cols="12"
                        md="5"
                      >
                        <b-form-group
                          :label="$t('form.price.label')"
                          :label-for="'discounts.' + index + '.price'"
                        >
                          <b-form-input
                            :id="'discounts.' + index + '.price'"
                            :ref="'discounts' + index + 'price'"
                            v-model="discount.price"
                            :type="`number`"
                            :step="`1`"
                            :state="errors && errors['discounts.' + index + '.price'] ? false : null"
                          />

                          <b-form-invalid-feedback v-if="errors && errors['discounts.' + index + '.price']">
                            {{ errors['discounts.' + index + '.price'][0] }}
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </b-col>
                      <b-col
                        cols="12"
                        md="4"
                      >
                        <b-form-group
                          :label="$t('form.started_at.label')"
                          :label-for="'discounts.' + index + '.started_at'"
                          :state="errors && errors['discounts.' + index + '.started_at'] ? false : null"
                        >
                          <b-form-datepicker
                            :id="'discounts.' + index + '.started_at'"
                            v-model="discount.started_at"
                            :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                          />

                          <b-form-invalid-feedback v-if="errors && errors['discounts.' + index + '.started_at']">
                            {{ errors['discounts.' + index + '.started_at'][0] }}
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </b-col>
                      <b-col
                        cols="12"
                        md="4"
                      >
                        <b-form-group
                          :label="$t('form.ended_at.label')"
                          :label-for="'discounts.' + index + '.ended_at'"
                          :state="errors && errors['discounts.' + index + '.ended_at'] ? false : null"
                        >
                          <b-form-datepicker
                            :id="'discounts.' + index + '.ended_at'"
                            v-model="discount.ended_at"
                            :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                          />

                          <b-form-invalid-feedback v-if="errors && errors['discounts.' + index + '.ended_at']">
                            {{ errors['discounts.' + index + '.ended_at'][0] }}
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </b-col>
                      <b-col
                        cols="12"
                        md="4"
                        class="mb-50"
                      >
                        <b-button
                          v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                          variant="outline-danger"
                          class="mt-0 mt-md-2"
                          block
                          @click="removeDiscount(index)"
                        >
                          <feather-icon
                            icon="XIcon"
                            class="mr-25"
                          />
                          <span>{{ $t('general.delete') }}</span>
                        </b-button>
                      </b-col>
                      <b-col cols="12">
                        <hr>
                      </b-col>
                    </b-row>
                  </div>

                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    @click="addDiscount"
                  >
                    <feather-icon
                      icon="PlusIcon"
                      class="mr-25"
                    />
                    <span>{{ $t('general.add') }}</span>
                  </b-button>

                  <b-row
                    v-if="errors && errors.discounts"
                  >
                    <b-col cols="12">
                      <b-form-invalid-feedback :state="false">
                        {{ errors.discounts[0] }}
                      </b-form-invalid-feedback>
                    </b-col>
                  </b-row>

                  <b-row>
                    <b-col cols="12">
                      <hr>
                    </b-col>
                  </b-row>
                </b-tab>
              </b-tabs>

              <!-- Action Buttons -->
              <b-row>
                <b-col cols="8">
                  <b-button
                    variant="primary"
                    class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                    block
                    @click="onSubmit"
                  >
                    {{ $t('general.save') }}
                  </b-button>
                </b-col>
                <b-col cols="4">
                  <b-button
                    v-if="$ability.can('destroy', 'products')"
                    variant="danger"
                    class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                    block
                    @click="onDelete"
                  >
                    <feather-icon
                      icon="TrashIcon"
                      size="16"
                      class="align-middle"
                    />
                  </b-button>
                </b-col>
              </b-row>
            </b-form>
          </b-card>
        </b-collapse>
      </b-card>
    </div>
  </b-overlay>
</template>

<script>
import GlobalMixin from '@/mixins/GlobalMixin'
import EditorMixin from '@/mixins/EditorMixin'
import _ from 'lodash'
import ToastificationContent from '@core/components/toastification/ToastificationContent'

export default {
  mixins: [GlobalMixin, EditorMixin],
  props: {
    mid: {
      type: Number,
      required: true,
    },
    pid: {
      type: Number,
      required: true,
    },
    languages: {
      type: Array,
      required: true,
    },
    rolesOptions: {
      type: Array,
      required: true,
    },
    stocksOptions: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      item: null,
    }
  },
  async created() {
    await this.$http.get(`/api/administration/products/${this.mid}`)
      .then(response => {
        const { data } = response.data

        this.item = this.transformData(data)
      })
  },
  methods: {
    selectedAvatar(data) {
      this.item.avatar = data
    },
    selectedGallery(data) {
      this.item.gallery = data
    },
    onSubmit() {
      const data = {
        title: this.item.title,
        type: 'variant',
        slug: this.item.slug,
        stocks: this.item.stocks,
        prices: this.item.prices,
        discounts: this.item.discounts,
        avatar: this.item.avatar,
        gallery: this.item.gallery,
        description: this.item.description,
        meta_description: this.item.meta_description,
        meta_h1: this.item.meta_h1,
        meta_keywords: this.item.meta_keywords,
        meta_title: this.item.meta_title,
        canonical: this.item.canonical,
        sku: this.item.sku,
        ean: this.item.ean,
      }

      this.$http.put(`/api/administration/products/${this.item.id}`, data)
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Success',
              icon: 'AlertCircleIcon',
              variant: 'success',
            },
          })
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: error.response.data.message,
              icon: 'AlertCircleIcon',
              variant: 'warning',
            },
          })
          this.$refs.roleForm.setErrors(error.response.data.errors)
        })
    },
    onDelete() {
      this.$http.delete(`/api/administration/products/${this.pid}/modifications/${this.item.id}`)
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Deleted',
              icon: 'AlertCircleIcon',
              variant: 'success',
            },
          })
          this.$router.go(this.$router.currentRoute)
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: error.response.data.message,
              icon: 'AlertCircleIcon',
              variant: 'warning',
            },
          })
        })
    },
    addStock() {
      this.item.stocks.push({
        quantity: 1,
        stock_id: null,
      })
    },
    removeStock(index) {
      this.item.stocks.splice(index, 1)
    },
    addPrice() {
      this.item.prices.push({
        price: 1,
        role_id: 2,
      })
    },
    removePrice(index) {
      this.item.prices.splice(index, 1)
    },
    addDiscount() {
      this.item.discounts.push({
        price: 1,
        percent: 0,
        started_at: new Date(),
        ended_at: new Date(),
        role_id: 2,
      })
    },
    removeDiscount(index) {
      this.item.discounts.splice(index, 1)
    },
    transformData(item) {
      const data = {
        modTitle: null,
        id: item.id,
        slug: item.slug,
        avatar: this.$options.filters.singleMedia(item.avatar),
        gallery: this.$options.filters.multipleMedia(item.gallery),
        stocks: item.stocks,
        prices: [],
        discounts: [],
        sku: item.sku,
        '1c_id': item['1c_id'],
        ean: item.ean,
        title: {},
        description: {},
        meta_description: {},
        meta_h1: {},
        meta_keywords: {},
        meta_title: {},
        canonical: {},
      }

      _.each(this.languages, language => {
        const translation = _.find(item.translations, { locale: language.code })

        data.title[language.code] = translation ? translation.title : null
        data.description[language.code] = translation ? translation.description : null
        data.meta_description[language.code] = translation ? translation.meta_description : null
        data.meta_h1[language.code] = translation ? translation.meta_h1 : null
        data.meta_keywords[language.code] = translation ? translation.meta_keywords : null
        data.meta_title[language.code] = translation ? translation.meta_title : null
        data.canonical[language.code] = translation ? translation.canonical : null
      })

      data.modTitle = _.head(Object.values(data.title))

      data.stocks = _.reduce(data.stocks, (result, stock) => {
        result.push({
          stock_id: stock.id,
          quantity: stock.quantity,
        })
        return result
      }, [])

      data.prices = _.reduce(item.prices, (result, price) => {
        result.push({
          id: price.id,
          role_id: price.role_id,
          price: price.price,
        })
        return result
      }, [])

      data.discounts = _.reduce(item.discounts, (result, discount) => {
        result.push({
          id: discount.id,
          role_id: discount.role_id,
          price: discount.price,
          started_at: discount.started_at,
          ended_at: discount.ended_at,
        })
        return result
      }, [])

      data.modTitle += _.reduce(item.attributes, (result, attribute) => {
        result += ` + [${attribute.attribute.title} - ${attribute.value.title}]`
        return result
      }, '')

      return data
    },
    setDiscountPercent(ref, ev) {
      const disc = (this.item.prices['0'].price - ((this.item.prices['0'].price * ev) / 100))
      this.item.discounts[ref].price = parseInt(disc, 10)
    },
  },
}
</script>
